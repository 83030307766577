<template>
	<div>
		<Modal
			v-if="businessData || eventId"
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new resouce for
						{{ businessData ? businessData.businessName : 'this event' }}.
					</h5>
					<h6
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0 m-0"
						id="logo"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedItem"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<BaseInput
						label="File Title"
						id="file title"
						placeholder="File Title"
						required
						v-model="fileTitle"
					/>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Resource</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import { mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/organizationFirebase';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
} from 'firebase/storage';

import BaseButton from '../../components/BaseButton.vue';
import { collection, doc, setDoc } from '@firebase/firestore';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Product',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		businessData: {
			type: Object,
			default: null,
		},
		eventId: {
			type: String,
			default: null,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				height: 100,
				addRemoveLinks: true,
				acceptedFiles: 'application/pdf',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			fileItemDocument: null,
			fileTitle: null,
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.fileItemDocument = null;
			this.fileTitle = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.fileItemDocument = file;
			this.fileTitle = file[0].name;
		},
		submit() {
			this.shake = false;

			if (this.fileItemDocument == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else if (this.$helpers.isBlank(this.fileTitle)) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const storage = getOrganizationStorage(this.organization);
				const firestore = getOrganizationFirestore(this.organization);
				if (this.businessData) {
					const businessRef = collection(
						firestore,
						`business/${this.businessData.businessId}/fileItems`
					);
					const newFileRef = doc(businessRef);
					const storageRef = firebaseStorageRef(
						storage,
						`business/${this.businessData.businessId}/fileItems/${newFileRef.id}/${this.fileItemDocument[0].name}`
					);
					this.isLoading = true;

					this.uploadImage(storageRef, newFileRef);
				} else if (this.eventId) {
					const eventRef = collection(
						firestore,
						`events/${this.eventId}/fileItems`
					);
					const newFileRef = doc(eventRef);
					const storageRef = firebaseStorageRef(
						storage,
						`events/${this.eventId}/fileItems/${newFileRef.id}/${this.fileItemDocument[0].name}`
					);
					this.isLoading = true;

					this.uploadImage(storageRef, newFileRef);
				}
			}
		},
		uploadImage(storageRef, newFileRef) {
			uploadBytes(storageRef, this.fileItemDocument[0])
				.then((result) => {
					const resultRef = result.ref;
					const contentType = result.metadata.contentType;
					const fileTitle = result.metadata.name;
					getDownloadURL(resultRef).then((downloadURL) => {
						this.sendFileToFirebase(
							downloadURL,
							contentType,
							fileTitle,
							newFileRef
						);
					});
				})
				.catch((error) => {
					//error uploading
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
		sendFileToFirebase(downloadURL, contentType, fileTitle, newFileRef) {
			const fileId = newFileRef.id;
			const fileContentType = contentType;
			var fileInfo = {
				fileContentType: fileContentType,
				fileId: fileId,
				fileLink: downloadURL,
				fileTitle: fileTitle,
				uploadTimestamp: new Date(),
			};
			setDoc(newFileRef, fileInfo)
				.then(() => {
					this.isLoading = false;
					this.$emit('newResource', fileInfo);
					this.resetView();
					this.closeModal();
				})
				.catch((error) => {
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
	},
	mounted() {},
};
</script>
