<template>
	<base-nav
		container-classes="container-fluid"
		class="navbar-top border-bottom navbar-expand"
	>
		<b-navbar-nav class="align-items-start mr-md-auto">
			<li class="nav-item d-xl-none">
				<!-- Sidenav toggler -->
				<div
					class="pr-3 sidenav-toggler"
					:class="{
						active: $sidebar.showSidebar,
						'sidenav-toggler-light': 'light',
					}"
					@click="toggleSidebar"
				>
					<div class="sidenav-toggler-inner">
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
						<i class="sidenav-toggler-line"></i>
					</div>
				</div>
			</li>
		</b-navbar-nav>
		<!--
		<div style="width: 100%">
			
<h2 class="m-0">{{ organization.appName }}</h2>
		
		</div>
		-->

		<!-- Search form 	 -->
		<b-form
			class="navbar-search"
			style="width: 100%"
			:class="{
				'navbar-search-light': 'default',
			}"
			id="navbar-search-main"
		>
			<b-form-group class="mb-0">
				<b-input-group class="input-group-alternative input-group-merge">
					<div class="input-group-prepend">
						<span class="input-group-text"><i class="fas fa-search"></i></span>
					</div>
					<b-form-input placeholder="Search" type="text" :disabled="true">
					</b-form-input>
				</b-input-group>
			</b-form-group>

			<button
				type="button"
				class="close"
				data-action="search-close"
				data-target="#navbar-search-main"
				aria-label="Close"
			>
				<span aria-hidden="true">×</span>
			</button>
		</b-form>

		<!-- Navbar links -->
		<b-navbar-nav class="align-items-center ml-md-auto">
			<!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
			<li class="nav-item d-sm-none">
				<a
					class="nav-link"
					href="#"
					data-action="search-show"
					data-target="#navbar-search-main"
				>
					<i class="ni ni-zoom-split-in"></i>
				</a>
			</li>
			<base-dropdown
				v-if="isFullAdmin"
				class="nav-item"
				tag="li"
				title-classes="nav-link"
				title-tag="a"
				icon="fas fa-bell"
				menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
			>
				<DashboardNotificationDropdownVue />
			</base-dropdown>
			<a class="nav-link" :href="DominionInformation.dominionWebsite">
				<i class="fas fa-question-circle"></i>
			</a>
		</b-navbar-nav>
		<b-navbar-nav class="align-items-center ml-auto ml-md-0">
			<base-dropdown
				menu-on-right
				class="nav-item"
				tag="li"
				title-tag="a"
				title-classes="nav-link pr-0"
			>
				<a href="#" class="nav-link pr-0" @click.prevent="" slot="title-container">
					<b-media no-body class="align-items-center">
						<b-avatar
							v-if="user"
							alt="Image placeholder"
							:src="user.profilePhotoURL"
							size="md"
							class="bg-light"
						/>
					</b-media>
				</a>

				<template>
					<b-dropdown-header class="noti-title">
						<h6 v-if="user" class="text-overflow m-0">
							Welcome, {{ user.firstName }}!
						</h6>
					</b-dropdown-header>
					<b-dropdown-item v-if="isFullAdmin" href="#!" @click.prevent="openProfile">
						<i class="fas fa-user"></i>
						<span>My Profile</span>
					</b-dropdown-item>
					<div class="dropdown-divider"></div>
					<b-dropdown-item v-on:click.prevent="logOutOfApp">
						<i class="fas fa-power-off"></i>
						<span>Logout</span>
					</b-dropdown-item>
				</template>
			</base-dropdown>
		</b-navbar-nav>
	</base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import { mapActions, mapGetters } from 'vuex';
import { DominionInformation } from '../../resources/DominionInformation';
import { DashboardChildren, DashboardPaths } from '../../routes/routesNames.js';
import DashboardNotificationDropdownVue from './HeaderFooterComponents/DashboardNotificationDropdown.vue';

export default {
	components: {
		BaseNav,
		DashboardNotificationDropdownVue,
	},
	props: {},
	computed: {
		...mapGetters({
			user: 'getUser',
			isFullAdmin: 'isFullAdmin',
			business: 'getBusiness',
			organization: 'getOrganization',
		}),
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	data() {
		return {
			activeNotifications: false,
			showMenu: false,
			searchModalVisible: false,
			searchQuery: '',
			DashboardPaths,
			DominionInformation,
		};
	},
	methods: {
		...mapActions(['logOut']),
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
		openProfile() {
			this.$router.replace({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: this.user.appUid,
				},
			});
		},
		logOutOfApp() {
			this.logOut()
				.then(() => {
					this.$router.push('/login');
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
	},
};
</script>
