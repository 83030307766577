<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Resources</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allFileItems.length }} Total</h3>
				</b-col>
			</b-row>
			<b-row v-if="allFileItems.length > 0" align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">Please limit these items to three.</h6>
			</b-row>
			<b-list-group>
				<b-list-group-item
					v-for="fileItem in allFileItems"
					:key="fileItem.fileId"
					@click="askFileQuestion(fileItem)"
				>
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex align-items-center">
							<i class="fas fa-file"></i>
							<div>
								<h5
									class="mb-0 ml-2"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ fileItem.fileTitle }}
								</h5>
							</div>
						</div>
						<div class="d-flex justify-content-end align-items-center m-0 p-0">
							<i class="fas fa-chevron-right ml-2 text-muted"></i>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteFileItem"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { collection, deleteDoc, doc, getDocs } from '@firebase/firestore';
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
export default {
	props: {
		businessId: {
			type: String,
			default: null,
		},
		eventId: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { Card, QuestionModal },
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			allFileItems: [],
			fileItem: null,
			modalQuestion: '',
			modalButtonData: [
				{
					id: 0,
					title: 'Delete Resource',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getFileItems() {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			var fileItemRef = collection(
				firestore,
				`business/${this.businessId}/fileItems`
			);
			if (this.eventId) {
				fileItemRef = collection(firestore, `events/${this.eventId}/fileItems`);
			}
			getDocs(fileItemRef).then((snapshot) => {
				this.isLoading = false;
				if (!snapshot.empty) {
					snapshot.forEach((document) => {
						this.allFileItems.push(document.data());
					});
				}
			});
		},
		askFileQuestion(fileIem) {
			this.modalQuestion = 'Do you want to delete this resource item?';
			this.fileItem = fileIem;
			this.showQuestionModal = true;
		},
		deleteFileItem() {
			const firestore = getOrganizationFirestore(this.organization);
			var fileItemRef = doc(
				firestore,
				`business/${this.businessId}/fileItems/${this.fileItem.fileId}`
			);
			if (this.eventId) {
				fileItemRef = collection(
					firestore,
					`events/${this.eventId}/fileItems/${this.fileItem.fileId}`
				);
			}
			deleteDoc(fileItemRef)
				.then(() => {
					this.isLoading = true;
					this.allFileItems = this.allFileItems.filter(
						(item) => item.fileId != this.fileItem.fileId
					);

					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: `${this.fileItem.fileTitle} Deleted.`,
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
	},
	mounted() {
		this.getFileItems();
	},
};
</script>
