import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { auth, firestore } from '../resources/firebase';
import { getOrganizationFirestore } from '../resources/organizationFirebase';
import store from './store';
import { DominionInformation } from '../resources/DominionInformation';

const actions = {
	getConferences({ commit }) {
		return new Promise((resolve, reject) => {
			const orgsRef = collection(firestore, 'organizations');
			getDocs(orgsRef)
				.then((orgSnapshot) => {
					return resolve(orgSnapshot.docs.map((e) => e.data()));
				})
				.catch((error) => {
					reject();
				});
		});
	},
	getOrgData({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			const orgId = DominionInformation.isDTW
				? DominionInformation.isDTW
					? 'dcVZPIWbcH4CsYA9Tdtt'
					: 'wgeFLzOassSx4LTlXH5P'
				: null;
			if (orgId) {
				const orgRef = doc(firestore, 'organizations', orgId);
				getDoc(orgRef).then((organizationSnapshot) => {
					const orgData = organizationSnapshot.data();
					commit('setOrganization', orgData);
					return resolve();
				});
			} else {
				reject();
			}
		}).catch((error) => {
			reject();
		});
	},
	getUserData({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			const userRef = doc(firestore, 'users', data.uid);
			getDoc(userRef)
				.then((snapshot) => {
					const userData = snapshot.data();
					const orgId = DominionInformation.isDTW
						? 'dcVZPIWbcH4CsYA9Tdtt'
						: 'wgeFLzOassSx4LTlXH5P';

					var updatedUserData = userData;
					updatedUserData.orgId = orgId;
					commit('setOrganizationUser', updatedUserData);
					return resolve();
				})
				.catch((error) => {
					dispatch('logOut');
					reject();
				});
		});
	},
	getAppUserData({ commit }, data) {
		return new Promise((resolve, reject) => {
			if (store.state.organizationUser.appUid) {
				const userRef = doc(
					getOrganizationFirestore(),
					'users',
					store.state.organizationUser.appUid
				);
				getDoc(userRef)
					.then((snapshot) => {
						const userData = snapshot.data();
						commit('setUser', userData);
						if (userData.isAdmin) {
							return resolve();
						} else if (userData.businessId) {
							const businessRef = doc(
								getOrganizationFirestore(),
								'business',
								userData.businessId
							);
							getDoc(businessRef)
								.then((businessSnapshot) => {
									const businessData = businessSnapshot.data();
									commit('setBusiness', businessData);
									return resolve();
								})
								.catch((error) => {
									return reject(error.message);
								});
						} else {
							return reject('No Business User');
						}
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return reject('No User');
			}
		});
	},
	showNotification({ commit }, data) {
		commit('setNotification', data);
	},
	removeNotification({ commit }) {
		commit('setNotification', null);
	},
	login({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(auth, data.email, data.password)
				.then((response) => {
					dispatch('getUserData', response.user)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error);
						});
				})
				.catch((error) => {
					commit('setError', error.message);
					reject(error);
				});
		});
	},
	logOut({ commit }) {
		return new Promise((resolve, reject) => {
			signOut(auth)
				.then(() => {
					commit('setUser', null);
					resolve();
				})
				.catch((error) => {
					commit('setError', error.message);
					reject(error);
				});
		});
	},
};

export default actions;
