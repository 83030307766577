<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<VendorTable
				:isLoading="isLoading"
				:tableData="allBusinessItems"
				:tableHeaders="businessTitles"
				@viewClicked="viewClicked"
				@deleteClicked="deleteClicked"
			/>
		</div>

		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="actionOnProduct"
			@noClicked="productData == null"
		/>

		<NewVendor
			:headerTitle="buttonItems[0].title"
			:showModal="showNewVendorModal"
			@close="showNewVendorModal = false"
			@newVendor="onNewVendor"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../components/Cards/Card.vue';
import Badge from '../../../components/Badge.vue';
import { mapActions, mapGetters } from 'vuex';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { collection, getDocs } from '@firebase/firestore';
import VendorTable from '../../Components/VendorViews/VendorTable.vue';
import NewVendor from '../../mainForms/NewVendor.vue';
import { DashboardChildren } from '../../../routes/routesNames';

export default {
	name: 'vendor',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		Card,
		Badge,
		QuestionModal,
		VendorTable,
		NewVendor,
	},
	data() {
		return {
			isLoading: true,
			showNewVendorModal: false,
			showQuestionModal: false,
			modalQuestion: '',
			selectedBusiness: null,
			allBusinessItems: [],
			buttonItems: [
				{
					index: 0,
					title: 'New Vendor',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
			businessTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Business Name',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.businessName;
					},
				},
				{
					key: 'businessBio',
					label: 'Description',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '40%' },
				},
				{
					key: 'businessId',
					label: 'Business Id',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '60%' },
				},
				{
					key: 'isActive',
					label: 'Active',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '10%' },
					sortByFormatted: (value, key, item) => {
						return item.isActive;
					},
					formatter: (value, key, item) => {
						return item.isActive;
					},
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			this.showQuestionModal = false;
			if (buttonItem.index == 0) {
				this.showNewVendorModal = true;
			}
		},
		getAllBusinessItems() {
			const firestore = getOrganizationFirestore(this.organization);
			const buisnessRef = collection(firestore, 'business');
			getDocs(buisnessRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((item) => {
						this.allBusinessItems.push(item.data());
						if (counter == total - 1) {
							this.isLoading = false;
						}
						counter += 1;
					});
				} else {
					this.isLoading = false;
				}
			});
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.vendorView.name,
				params: {
					businessId: item.businessId,
				},
			});
		},
		deleteClicked(item) {},
		actionOnProduct() {
			/*
			if (this.productData.productIsActive) {
			} else {
				//reactivate product
				console.log(`Moses Reactivate Called On ${this.productData.productId}`);
			}
            */
		},
		editProduct() {
			//console.log(`Moses edit on ${this.productData.productId}`);
		},
		onUpdateVendor(vendor) {
			/*
			this.isLoading = true;

			if (product.productIsActive) {
				//remove from inactive & add to active
				this.allInactiveProducts = this.allInactiveProducts.filter(
					(item) => item.productId != product.productId
				);
				this.allActiveProducts.push(product);
				this.allActiveProducts.sort((a, b) =>
					a.productTimestamp < b.productTimestamp ? 1 : -1
				);
			} else {
				//remove from active & add to inactive
				this.allActiveProducts = this.allActiveProducts.filter(
					(item) => item.productId != product.productId
				);
				this.allInactiveProducts.push(product);
				this.allInactiveProducts.sort((a, b) =>
					a.productTimestamp < b.productTimestamp ? 1 : -1
				);
			}

			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${product.productTitle} has been updated.`,
			});
            */
		},
		onDeleteVendor(vendor) {
			/*
			this.isLoading = true;
			this.allProductItems = this.allProductItems.filter(
				(item) => item.productId != product.productId
			);
			this.allActiveProducts = this.allActiveProducts.filter(
				(item) => item.productId != product.productId
			);
			this.allInactiveProducts = this.allInactiveProducts.filter(
				(item) => item.productId != product.productId
			);

			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${product.productTitle} Deleted.`,
			});
            */
		},
		onNewVendor(vendor) {
			this.isLoading = true;
			this.allBusinessItems.push(vendor);
			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${vendor.businessName} Added.`,
			});
		},
	},
	mounted() {
		this.getAllBusinessItems();
	},
};
</script>
