<template>
	<div>
		<Card>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Event Information</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<BaseButton
						:disabled="!isUpdatingProfile"
						size="sm"
						@click="showQuestionModal = true"
						>Update Event</BaseButton
					>
				</b-col>
			</b-row>
			<form>
				<base-input
					label="Event Title"
					id="event title"
					placeholder="Event Title"
					required
					v-model="eventTitle"
				/>

				<base-input
					label="Event Id"
					id="event id"
					placeholder="Event Id"
					disabled
					v-model="eventData.eventId"
				/>

				<base-input label="Event Description">
					<textarea
						placeholder="Enter your description..."
						v-model="eventDescription"
						class="form-control"
						id="event description"
						rows="4"
						required
					></textarea>
				</base-input>

				<b-row>
					<b-col>
						<base-input
							required
							label="Event Start Time"
							type="datetime-local"
							v-model="eventStart"
							id="event start"
						/>
					</b-col>
					<b-col>
						<base-input
							label="Event End Time"
							type="datetime-local"
							v-model="eventEnd"
							id="event end"
						/>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<base-input
							v-model="eventLocation"
							label="Event Location"
							placeholder="Event Location"
							id="event location"
						/>
					</b-col>
				</b-row>

				<base-input placeholder="Select A Speaker" label="Speaker #1">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker1"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker1"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
				<base-input placeholder="Select A Speaker" label="Speaker #2">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker2"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker2"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
				<base-input placeholder="Select A Speaker" label="Speaker #3">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker3"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker3"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
				<base-input placeholder="Select A Speaker" label="Speaker #4">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker4"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker4"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
				<base-input placeholder="Select A Speaker" label="Speaker #5">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker5"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker5"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
				<base-input placeholder="Select A Speaker" label="Speaker #6">
					<select
						class="form-control"
						id="eventFacultyLeader"
						v-model="eventSpeaker6"
					>
						<option
							v-for="leader in allSpeakers"
							:key="leader.speakerId"
							:value="leader.speakerId"
							:selected="leader.speakerId == eventSpeaker6"
						>
							{{ leader.speakerName }}
						</option>
					</select>
				</base-input>
			</form>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="updateEvent"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import CurrencyInput from '../../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import moment from 'moment';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import { mapActions, mapGetters } from 'vuex';
import { arrayUnion, doc, setDoc } from '@firebase/firestore';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		eventData: null,
		allSpeakers: [],
	},
	components: {
		Card,
		BaseButton,
		CurrencyInput,
		BaseSwitch,
		QuestionModal,
	},
	data() {
		return {
			isUpdatingProfile: false,
			showQuestionModal: false,
			modalQuestion: "Are you sure you want to update this event's information?",
			eventTitle: null,
			eventDescription: null,
			eventStart: null,
			eventEnd: null,
			eventLocation: null,
			eventSpeaker1: null,
			eventSpeaker2: null,
			eventSpeaker3: null,
			eventSpeaker4: null,
			eventSpeaker5: null,
			eventSpeaker6: null,
		};
	},
	watch: {
		eventTitle(val) {
			if (this.$helpers.isBlank(val)) {
				this.eventTitle = this.eventData.eventTitle;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventDescription(val) {
			if (this.$helpers.isBlank(val)) {
				this.eventDescription = this.eventData.eventDescription;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventStart(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventEnd(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventLocation(val) {
			if (this.$helpers.isBlank(val)) {
				this.eventLocation = this.eventData.eventLocation;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker1(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker2(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker3(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker4(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker5(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		eventSpeaker6(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
	},
	methods: {
		...mapActions(['showNotification']),
		resetView() {
			this.eventStart = this.setEventStart();
			this.eventEnd = this.setEventEnd();
			this.setFacultyLeader();
			this.eventTitle = this.eventData.eventTitle;
			this.eventDescription = this.eventData.eventDescription;
			this.eventLocation = this.eventData.eventLocation;
		},
		checkIfUpdateNeeded() {
			if (
				this.eventTitle != this.eventData.eventTitle ||
				this.eventDescription != this.eventData.eventDescription ||
				this.eventLocation != this.eventData.eventLocation
			) {
				return true;
			} else if (this.eventStart != this.setEventStart()) {
				return true;
			} else if (this.eventEnd != this.setEventEnd()) {
				return true;
			} else if (this.eventData.eventSpeaker) {
				if (
					this.eventSpeaker1 != this.eventData.eventSpeaker[0] ||
					this.eventSpeaker2 != this.eventData.eventSpeaker[1] ||
					this.eventSpeaker3 != this.eventData.eventSpeaker[2] ||
					this.eventSpeaker4 != this.eventData.eventSpeaker[3] ||
					this.eventSpeaker5 != this.eventData.eventSpeaker[4] ||
					this.eventSpeaker6 != this.eventData.eventSpeaker[5]
				) {
					return true;
				}
			} else {
				return true;
			}
			return false;
		},
		setEventStart() {
			var checkDate = this.eventData.eventStart.toDate();
			var eDate = moment(checkDate).format('yyyy-MM-DDTHH:mm');
			return eDate;
		},
		setEventEnd() {
			if (this.eventData.eventEnd != null) {
				var checkDate = this.eventData.eventEnd.toDate();
				var eDate = moment(checkDate).format('yyyy-MM-DDTHH:mm');
				return eDate;
			} else {
				return null;
			}
		},
		setFacultyLeader() {
			if (this.eventData.eventSpeaker) {
				this.eventSpeaker1 = this.eventData.eventSpeaker[0];
				this.eventSpeaker2 = this.eventData.eventSpeaker[1];
				this.eventSpeaker3 = this.eventData.eventSpeaker[2];
				this.eventSpeaker4 = this.eventData.eventSpeaker[3];
				this.eventSpeaker5 = this.eventData.eventSpeaker[4];
				this.eventSpeaker6 = this.eventData.eventSpeaker[5];
			}
		},
		updateEvent() {
			const firestore = getOrganizationFirestore(this.organization);
			const eventRef = doc(firestore, `events/${this.eventData.eventId}`);

			var updateInfo = {};

			updateInfo['eventStart'] = new Date(this.eventStart);

			if (this.eventEnd != null) {
				updateInfo['eventEnd'] = new Date(this.eventEnd);
			}

			if (this.eventTitle != this.eventData.eventTitle) {
				updateInfo['eventTitle'] = this.eventTitle;
			}

			if (this.eventDescription != this.eventData.eventDescription) {
				updateInfo['eventDescription'] = this.eventDescription;
			}

			if (this.eventLocation != this.eventData.eventLocation) {
				updateInfo['eventLocation'] = this.eventLocation;
			}

			const eventSpeaker = [];

			if (this.eventData.eventSpeaker) {
				if (this.eventSpeaker1 != this.eventData.eventSpeaker[0]) {
					eventSpeaker.push(this.eventSpeaker1);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}

				if (this.eventSpeaker2 != this.eventData.eventSpeaker[1]) {
					eventSpeaker.push(this.eventSpeaker1);
					eventSpeaker.push(this.eventSpeaker2);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}

				if (this.eventSpeaker3 != this.eventData.eventSpeaker[2]) {
					eventSpeaker.push(this.eventSpeaker1);
					eventSpeaker.push(this.eventSpeaker2);
					eventSpeaker.push(this.eventSpeaker3);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}

				if (this.eventSpeaker4 != this.eventData.eventSpeaker[3]) {
					eventSpeaker.push(this.eventSpeaker1);
					eventSpeaker.push(this.eventSpeaker2);
					eventSpeaker.push(this.eventSpeaker3);
					eventSpeaker.push(this.eventSpeaker4);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}

				if (this.eventSpeaker5 != this.eventData.eventSpeaker[4]) {
					eventSpeaker.push(this.eventSpeaker1);
					eventSpeaker.push(this.eventSpeaker2);
					eventSpeaker.push(this.eventSpeaker3);
					eventSpeaker.push(this.eventSpeaker4);
					eventSpeaker.push(this.eventSpeaker5);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}

				if (this.eventSpeaker6 != this.eventData.eventSpeaker[5]) {
					eventSpeaker.push(this.eventSpeaker1);
					eventSpeaker.push(this.eventSpeaker2);
					eventSpeaker.push(this.eventSpeaker3);
					eventSpeaker.push(this.eventSpeaker4);
					eventSpeaker.push(this.eventSpeaker5);
					eventSpeaker.push(this.eventSpeaker6);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
			} else {
				if (this.eventSpeaker1) {
					eventSpeaker.push(this.eventSpeaker1);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
				if (this.eventSpeaker2) {
					eventSpeaker.push(this.eventSpeaker2);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
				if (this.eventSpeaker3) {
					eventSpeaker.push(this.eventSpeaker3);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
				if (this.eventSpeaker4) {
					eventSpeaker.push(this.eventSpeaker4);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
				if (this.eventSpeaker5) {
					eventSpeaker.push(this.eventSpeaker5);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
				if (this.eventSpeaker6) {
					eventSpeaker.push(this.eventSpeaker6);
					updateInfo['eventSpeaker'] = eventSpeaker;
				}
			}

			setDoc(eventRef, updateInfo, { merge: true })
				.then(() => {
					this.updateData();
					this.showNotification({
						type: 'success',
						message: 'Event Updated.',
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		updateData() {
			this.eventData.eventTitle = this.eventTitle;
			this.eventData.eventDescription = this.eventDescription;
			this.eventData.eventLocation = this.eventLocation;
			this.$emit('updateEvent', this.eventData);
		},
	},
	mounted() {
		this.resetView();
		//this.allSpeakers.unshift(null);
	},
};
</script>
