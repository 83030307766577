<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new event for all users to view.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
					<BaseInput
						label="Event Title *"
						id="event title"
						placeholder="Event Title"
						required
						v-model="event.eventTitle"
					>
					</BaseInput>
					<BaseInput label="Event Description  *">
						<textarea
							placeholder="Enter your description..."
							v-model="event.eventDescription"
							class="form-control"
							id="event description"
							rows="4"
							required
						/>
					</BaseInput>
					<b-row>
						<b-col>
							<base-input
								required
								label="Event Start Time  *"
								type="datetime-local"
								v-model="event.eventStart"
								id="event start"
							/>
						</b-col>
						<b-col>
							<base-input
								label="Event End Time"
								type="datetime-local"
								v-model="event.eventEnd"
								id="event end"
							/>
						</b-col>
					</b-row>

					<BaseInput
						label="Event Location  *"
						id="event location"
						placeholder="Event Location"
						required
						v-model="event.eventLocation"
					>
					</BaseInput>
					<base-input label="Select A Presentor  *" placeholder="Select A Speaker">
						<select
							class="form-control"
							id="eventSpeaker1"
							v-model="event.eventSpeaker1"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>
					<base-input
						label="Select A Second Presentor"
						v-if="event.eventSpeaker1"
						placeholder="Select A Second Speaker"
					>
						<select
							class="form-control"
							id="eventSpeaker2"
							v-model="event.eventSpeaker2"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>
					<base-input
						label="Select A Third Presentor"
						v-if="event.eventSpeaker2"
						placeholder="Select A Third Speaker"
					>
						<select
							class="form-control"
							id="eventSpeaker3"
							v-model="event.eventSpeaker3"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>
					<base-input
						label="Select A Fourth Presentor"
						v-if="event.eventSpeaker3"
						placeholder="Select A Fourth Speaker"
					>
						<select
							class="form-control"
							id="eventSpeaker4"
							v-model="event.eventSpeaker4"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>

					<base-input
						label="Select A Fifth Presentor"
						v-if="event.eventSpeaker4"
						placeholder="Select A Fifth Speaker"
					>
						<select
							class="form-control"
							id="eventSpeaker5"
							v-model="event.eventSpeaker5"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>
					<base-input
						label="Select A Six Presentor"
						v-if="event.eventSpeaker5"
						placeholder="Select A Six Speaker"
					>
						<select
							class="form-control"
							id="eventSpeaker6"
							v-model="event.eventSpeaker6"
						>
							<option
								v-for="leader in allSpeakers"
								:key="leader.speakerId"
								:value="leader.speakerId"
							>
								{{ leader.speakerName }}
							</option>
						</select>
					</base-input>
					<ButtonRadioGroup
						v-if="event.eventReoccuringSwitch"
						:options="reoccuringOptions"
						v-model="event.eventReoccuring"
					/>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Event</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import Card from '../../components/Cards/Card.vue';
import DropzoneFileUpload from '../../components/Inputs/DropzoneFileUpload.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import { getOrganizationFirestore } from '../../resources/organizationFirebase';
import { mapGetters } from 'vuex';
import { collection, doc, setDoc } from '@firebase/firestore';
import BaseInput from '../../components/Inputs/BaseInput.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Event',
		},
		allSpeakers: {
			type: Array,
			default: [],
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		Card,
		ButtonRadioGroup,
		DropzoneFileUpload,
		CurrencyInput,
		BaseInput,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			event: {
				eventTitle: null,
				eventDescription: null,
				eventStart: null,
				eventEnd: null,
				eventLocation: null,
				eventSpeaker1: null,
				eventSpeaker2: null,
				eventSpeaker3: null,
				eventSpeaker4: null,
				eventSpeaker5: null,
				eventSpeaker6: null,
			},
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.event.eventTitle = null;
			this.event.eventDescription = null;
			this.event.eventStart = null;
			this.event.eventEnd = null;
			this.event.eventLocation = null;
			this.event.eventSpeaker1 = null;
			this.event.eventSpeaker2 = null;
			this.event.eventSpeaker3 = null;
			this.event.eventSpeaker4 = null;
			this.event.eventSpeaker5 = null;
			this.event.eventSpeaker6 = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.shake = false;

			const eventTitle = this.event.eventTitle;
			const eventDescription = this.event.eventDescription;
			const eventStart = this.event.eventStart;
			const eventEnd = this.event.eventEnd;
			const eventSpeaker = this.event.eventSpeaker;
			const eventLocation = this.event.eventLocation;

			if (
				this.isEmpty(eventTitle) ||
				this.isEmpty(eventDescription) ||
				this.isEmpty(eventLocation)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (eventStart == null) {
				this.errorMessage = 'Please select a start date.';
				this.shake = true;
				return;
			} else if (eventSpeaker1 == null) {
				this.errorMessage = 'Please select a speaker.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.isLoading = true;

				const firestore = getOrganizationFirestore(this.organization);
				const eventsRef = collection(firestore, `events`);
				const newEventRef = doc(eventsRef);
				const eventId = newEventRef.id;

				var eventInfo = {
					eventId: eventId,
					eventDescription: this.event.eventDescription,
					eventLocation: this.event.eventLocation,
					eventStart: new Date(this.event.eventStart),
					eventTitle: this.event.eventTitle,
				};

				if (eventEnd != null) {
					eventInfo['eventEnd'] = new Date(this.event.eventEnd);
				}

				var eventSpeakers = [];
				if (this.event.eventSpeaker1 != null) {
					eventSpeakers.push(this.event.eventSpeaker1);
					if (this.event.eventSpeaker2 != null) {
						eventSpeakers.push(this.event.eventSpeaker2);
						if (this.event.eventSpeaker3 != null) {
							eventSpeakers.push(this.event.eventSpeaker3);
							if (this.event.eventSpeaker4 != null) {
								eventSpeakers.push(this.event.eventSpeaker4);
								if (this.event.eventSpeaker5 != null) {
									eventSpeakers.push(this.event.eventSpeaker5);
									if (this.event.eventSpeaker6 != null) {
										eventSpeakers.push(this.event.eventSpeaker6);
									}
								}
							}
						}
					}
				}
				if (eventSpeakers.length > 0) {
					eventInfo['eventSpeaker'] = eventSpeakers;
				}

				setDoc(newEventRef, eventInfo)
					.then(() => {
						this.isLoading = false;
						this.$emit('newEvent', eventInfo);
						this.resetView();
						this.closeModal();
					})
					.catch((error) => {
						this.isLoading = false;
						this.errorMessage = error.message;
						this.shake = true;
					});
			}
		},
		isEmpty(str) {
			return this.$helpers.isBlank(str);
		},
	},
	mounted() {},
};
</script>
