<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>

		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<!-- Fullcalendar -->
					<div class="card card-calendar">
						<!-- Card header -->
						<div class="card-header">
							<!-- Title -->
							<b-row>
								<b-col class="d-flex justify-content-start align-items-center">
									<BaseButton size="sm" :icon="true" @click="prev"
										><i class="m-0 p-0 fas fa-angle-left"></i>
									</BaseButton>
									<h2 class="text-dark mb-0 mx-3">
										{{ targetDate.month }} {{ targetDate.day }},
										{{ targetDate.year }}
									</h2>
									<BaseButton size="sm" :icon="true" @click="next"
										><i class="m-0 p-0 fas fa-angle-right"></i>
									</BaseButton>
								</b-col>
								<b-col class="d-flex justify-content-end align-items-center">
									<base-button class="btn btn-sm btn-default" @click="switchView()">
										Switch View
									</base-button>
									<base-button class="btn btn-sm" @click="changeView('timeGridDay')">
										Today
									</base-button>
								</b-col>
							</b-row>
							<div v-if="false" class="row justify-content-between align-items-center">
								<BaseButton size="sm" :icon="true" @click.prevent="prev"
									><i class="fas fa-angle-left"></i>
								</BaseButton>
								<h3 class="text-dark mb-0 ml-auto mr-auto">
									{{ targetDate.month }} {{ targetDate.year }}
								</h3>
								<BaseButton size="sm" :icon="true" @click.prevent="prev"
									><i class="fas fa-angle-right"></i>
								</BaseButton>
								<div class="col-lg-6 col-7 justify-items-center d-flex">
									<h3 class="text-dark mb-0 ml-auto mr-auto">
										{{ targetDate.month }} {{ targetDate.year }}
									</h3>
								</div>

								<div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
									<a
										href="#"
										@click.prevent="prev"
										class="fullcalendar-btn-prev btn btn-sm btn-default"
									>
										<i class="fas fa-angle-left"></i>
									</a>
									<a
										href="#"
										@click.prevent="next"
										class="fullcalendar-btn-next btn btn-sm btn-default"
									>
										<i class="fas fa-angle-right"></i>
									</a>
									<base-button
										v-if="false"
										class="btn btn-sm btn-default"
										@click="changeView('dayGridMonth')"
									>
										Month
									</base-button>
									<base-button
										v-if="false"
										class="btn btn-sm btn-default"
										@click="changeView('dayGridWeek')"
									>
										Week
									</base-button>
									<base-button
										v-if="false"
										class="btn btn-sm btn-default"
										@click="changeView('timeGridDay')"
									>
										Day
									</base-button>
								</div>
							</div>
						</div>
						<!-- Card body -->
						<div class="card-body p-0 card-calendar-body">
							<div id="fullCalendar"></div>
						</div>
					</div>
					<b-overlay :show="isLoading" rounded="sm" no-wrap></b-overlay>
				</div>
			</div>
		</div>

		<NewEvent
			:headerTitle="buttonItems[0].title"
			:showModal="showNewEventModal"
			:allSpeakers="allSpeakers"
			@close="showNewEventModal = false"
			@newEvent="onNewEvent"
		/>

		<AdminUploadFile
			:showModal="showFileModal"
			@close="showFileModal = false"
			@addedFile="addedFile"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Modal from '../../../components/Modals/Modal.vue';
import calendarSettings from '../../../data/calendarSettings';
import { Calendar } from '@fullcalendar/core';
import dayGridMonth from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridDay from '@fullcalendar/timegrid';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import NewEvent from '../../mainForms/NewEvent.vue';
import NewCalendarItem from '../../mainForms/NewCalendarItem.vue';
import { mapActions, mapGetters } from 'vuex';
import {
	getOrganizationDatabase,
	getOrganizationFirestore,
} from '../../../resources/organizationFirebase';
import { DashboardChildren } from '../../../routes/routesNames';
import BaseButton from '../../../components/BaseButton.vue';
import { collection, getDocs, doc, setDoc } from '@firebase/firestore';
import AdminUploadFile from '../../mainForms/AdminUploadFile.vue';
import csvtojson from 'csvtojson';

const targetDate = new Date();
const y = targetDate.getFullYear();
const m = targetDate.getMonth();
const d = targetDate.getDate();
var calendar;

export default {
	name: 'calendar',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		RouteBreadCrumb,
		Modal,
		NewEvent,
		NewCalendarItem,
		BaseButton,
		AdminUploadFile,
	},
	data() {
		return {
			isLoading: false,
			isDay: true,
			showEventViewModal: false,
			showNewEventModal: false,
			showFileModal: false,
			calendarSettings: calendarSettings,
			buttonItems: [
				{
					index: 0,
					title: 'New Event',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
			targetDate: {
				day: targetDate.toLocaleString('default', { day: '2-digit' }),
				month: targetDate.toLocaleString('default', { month: 'long' }),
				year: targetDate.toLocaleString('default', { year: 'numeric' }),
			},
			calendarEvents: [],
			allEvents: [],
			allSpeakers: [],
			eventData: {},
			eventColors: [
				'bg-info',
				'bg-orange',
				'bg-red',
				'bg-green',
				'bg-default',
				'bg-blue',
				'bg-purple',
				'bg-yellow',
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				//this.showFileModal = true;
				this.showNewEventModal = true;
			}
		},
		switchView() {
			if (this.isDay) {
				this.changeView('timeGridDay');
				this.isDay = false;
			} else {
				this.changeView('dayGridMonth');
				this.isDay = true;
			}
		},
		getEvents() {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'events');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((item) => {
						var event = item.data();
						var calendarEventData = {
							id: event.eventId,
							title: event.eventTitle,
							start: event.eventStart.toDate(),
							allDay: false,
							className: 'event-default',
							extendedProps: {
								eventData: event,
							},
						};
						this.allEvents.push(calendarEventData);
						if (counter == total - 1) {
							this.getSpeakers();
						}
						counter += 1;
					});
				} else {
					this.getSpeakers();
				}
			});
		},
		getSpeakers() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'speakers');
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((speaker) => {
						const speakerData = speaker.data();
						this.allSpeakers.push(speakerData);
						if (counter == total - 1) {
							this.initCalendar();
						}
						counter += 1;
					});
				} else {
					this.initCalendar();
				}
			});
		},
		onNewEvent(event) {
			this.isLoading = true;
			var calendarEventData = {};
			calendarEventData = {
				id: event.eventId,
				title: event.eventTitle,
				start: new Date(event.eventStart),
				allDay: false,
				className: 'event-default',
				extendedProps: {
					eventData: event,
				},
			};
			this.calendarEvents.push(calendarEventData);
			calendar.addEvent(calendarEventData);
			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${event.eventTitle} Added.`,
			});
		},
		initCalendar() {
			var calendarEl = document.getElementById('fullCalendar');
			this.allEvents.forEach((event) => {
				this.calendarEvents.push(event);
			});

			this.isLoading = false;

			calendar = new Calendar(calendarEl, {
				plugins: [dayGridMonth, timeGridDay, interactionPlugin],
				initialView: 'dayGridMonth',
				selectable: true,
				headerToolbar: false,
				select: (info) => {
					const eventSelectedStart = info.start;
					const eventSelectedEnd = info.end;
					//this.showNewEventModal = true;
				},
				eventClick: (info) => {
					const eventSelectedStart = info.event.start.getTime();
					this.openEvent(eventSelectedStart, info.event.extendedProps.eventData);
				},
				events: this.calendarEvents,
			});
			calendar.render();
		},
		changeView(newView) {
			calendar.changeView(newView);
			calendar.view.title;
			console.log(calendar.getDate());
		},
		addedFile(file) {
			this.processFile(file, (result) => {
				this.handleFileResult(result);
			});
		},
		processFile(file, callback) {
			const filereader = new FileReader();
			filereader.onload = function (e) {
				const text = e.target.result;
				callback(text);
			};
			filereader.readAsText(file);
		},
		handleFileResult(text) {
			this.showFileModal = false;
			csvtojson()
				.fromString(text)
				.then((result) => {
					if (result.length > 200) {
						this.showNotification({
							type: 'danger',
							message: `Please limit to only 200 entries at a time.`,
						});
					} else {
						this.addedEvents(result);
					}
				});
		},
		addedEvents(allEvents) {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			const total = allEvents.length;
			var counter = 0;
			allEvents.forEach((value) => {
				const eventRef = doc(collection(firestore, 'events'));
				console.log(`we Got ${value}`);

				var eventData = value;
				eventData.eventId = eventRef.id;
				if (eventData.eventStart) {
					const estTime = new Date(eventData.eventStart);
					estTime.setHours(estTime.getHours() - 3);
					eventData.eventStart = estTime;
				}
				if (eventData.eventEnd) {
					eventData.eventEnd = new Date(eventData.eventEnd);
				}
				if (eventData.presentor) {
					if (eventData.presentor.includes(',')) {
						var result;
						var eventSpeakers = [];
						result = eventData.presentor.split(',');
						result.forEach((e) => {
							eventSpeakers.push(e.replace(/\s/g, ''));
						});
						eventData.eventSpeaker = eventSpeakers;
					} else {
						eventData.eventSpeaker = [eventData.presentor.replace(/\s/g, '')];
					}
				}
				const finalData = this.removeAllBlankObjects(eventData);

				setDoc(eventRef, finalData)
					.then(() => {
						console.log(`Successful uploaded: ${finalData.eventTitle}`);
						if (counter == total - 1) {
							this.isLoading = false;
							console.log('Finished With: ', total);
						}
						counter += 1;
					})
					.catch((error) => {
						console.log(`Successful uploaded: ${finalData.eventTitle}`);
						if (counter == total - 1) {
							this.isLoading = false;
							console.log('Finished With: ', total);
						}
						counter += 1;
					});
			});
		},
		removeAllBlankObjects(detailsObj) {
			return Object.entries(detailsObj).reduce(
				(a, [k, v]) => (v ? ((a[k] = v), a) : a),
				{}
			);
		},
		openEvent(eventStart, eventData) {
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventId: eventData.eventId,
				},
				query: {
					eventStart: eventStart,
				},
			});
		},
		next() {
			calendar.next();
			this.targetDate.day = calendar
				.getDate()
				.toLocaleString('default', { day: '2-digit' });
			this.targetDate.month = calendar
				.getDate()
				.toLocaleString('default', { month: 'long' });
			this.targetDate.year = calendar
				.getDate()
				.toLocaleString('default', { year: 'numeric' });
		},
		prev() {
			calendar.prev();
			this.targetDate.day = calendar
				.getDate()
				.toLocaleString('default', { day: '2-digit' });
			this.targetDate.month = calendar
				.getDate()
				.toLocaleString('default', { month: 'long' });
			this.targetDate.year = calendar
				.getDate()
				.toLocaleString('default', { year: 'numeric' });
		},
		saveEvent() {
			this.events.push(JSON.parse(JSON.stringify(event)));
			console.log(this.events);
		},
		editEvent() {},
		deleteEvent() {},
	},
	mounted() {
		this.getEvents();
	},
};
</script>
<style lang="scss">
@import '~@/assets/sass/core/vendors/fullcalendar';
</style>
