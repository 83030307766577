<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new user to the app.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<b-row>
						<b-col>
							<BaseInput
								label="First Name"
								id="first name"
								placeholder="First Name"
								required
								v-model="userData.firstName"
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Last Name"
								id="last name"
								placeholder="Last Name"
								required
								v-model="userData.lastName"
							/>
						</b-col>
					</b-row>
					<BaseInput
						label="Email"
						id="email"
						placeholder="Email"
						required
						v-model="userData.email"
					/>

					<BaseInput label="Account Level" placeholder="Account Level">
						<select
							class="form-control"
							id="account type"
							v-model="userData.accountLevel"
						>
							<option v-for="value in arrayOfLevels" :key="value">
								{{ value }}
							</option>
						</select>
					</BaseInput>

					<BaseInput label="Business" placeholder="Business">
						<select
							class="form-control"
							id="business Id"
							v-model="userData.businessId"
						>
							<option
								v-for="business in allBusinessItems"
								:key="business.businessId"
								:value="business.businessId"
							>
								{{ business.businessName }}
							</option>
						</select>
					</BaseInput>

					<ButtonRadioGroup
						label="Email Template"
						:options="emailTemplates"
						v-model="userData.emailTemplate"
					></ButtonRadioGroup>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add User & Send Email</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import { mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/organizationFirebase';

import BaseButton from '../../components/BaseButton.vue';
import { collection, doc, getDocs, setDoc } from '@firebase/firestore';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New User',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
		ButtonRadioGroup,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			arrayOfLevels: [null, 'bronze', 'silver', 'gold'],
			allBusinessItems: [],
			userData: {
				firstName: null,
				lastName: null,
				email: null,
				emailTemplate: 'user',
				businessId: null,
				accountLevel: null,
			},
			emailTemplates: [
				{
					value: 'user',
					name: 'User',
				},
				{
					value: 'non-user',
					name: 'Non-User',
				},
				{
					value: 'vendor',
					name: 'Vendor',
				},
				{
					value: null,
					name: 'No Email',
				},
			],
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.userData.firstName = null;
			this.userData.lastName = null;
			this.userData.email = null;
			this.userData.businessId = null;
			this.userData.accountLevel = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		getAllBusinessItems() {
			const firestore = getOrganizationFirestore(this.organization);
			const buisnessRef = collection(firestore, 'business');
			getDocs(buisnessRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((item) => {
						this.allBusinessItems.push(item.data());
						if (counter == total - 1) {
							this.isLoading = false;
						}
						counter += 1;
					});
				} else {
					this.isLoading = false;
				}
			});
		},
		submit() {
			this.shake = false;

			if (
				this.$helpers.isBlank(this.userData.firstName) ||
				this.$helpers.isBlank(this.userData.lastName) ||
				this.$helpers.isBlank(this.userData.email) ||
				this.$helpers.isBlank(this.userData.accountLevel)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const firestore = getOrganizationFirestore(this.organization);
				const tempUsersRef = collection(firestore, 'tempUsers');
				const newUserRef = doc(tempUsersRef);

				this.isLoading = true;

				this.sendUserToFirebase(newUserRef);
			}
		},
		sendUserToFirebase(newUserRef) {
			const uid = newUserRef.id;
			var userInfo = this.userData;
			const tempPswrd = Array(10)
				.fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
				.map(function (x) {
					return x[Math.floor(Math.random() * x.length)];
				})
				.join('');

			if (userInfo.businessId == null) {
				delete userInfo['businessId'];
			}

			userInfo['uid'] = uid;
			userInfo['tempPswrd'] = tempPswrd;
			userInfo['email'] = userInfo.email.toLowerCase();

			setDoc(newUserRef, userInfo)
				.then(() => {
					this.isLoading = false;
					this.$emit('newUser', userInfo);
					this.resetView();
					this.closeModal();
				})
				.catch((error) => {
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
	},
	mounted() {
		this.getAllBusinessItems();
	},
};
</script>
